<template>
    <el-form-item :label="$t('calendar.employees')">
        <el-select
            v-model="selectedEmployees"
            :placeholder="$t('calendar.employees')"
            multiple
            filterable
            class="mb-2"
            @change="onChange"
        >
            <el-option
                v-for="employee in employees"
                :key="employee.uuid"
                :label="employee.name + ' ' + employee.surname"
                :value="employee.uuid"
            />
        </el-select>
    </el-form-item>
</template>

<script>
export default {
    props: {
        selectedEmployeesProp: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            selectedEmployees: this.selectedEmployeesProp,
        };
    },

    computed: {
        employees() {
            return this.$store.state.employeesActiveDropdown;
        },
    },

    methods: {
        onChange() {
            this.$emit('change', this.selectedEmployees);
        },
    },
};
</script>
